<template>
  <div class="stayPageWrap">
    <m-layout-bar>
      <div class="stayWrap">
        <div class="bannerWrap">
          <img :src="require('../assets/image/stay/banner.png')" alt />
          <div class="btnWrap">
            <div class="btn">住的溜溜</div>
            <img class="decorate d1 ifocus_left" :src="require('../assets/image/stay/d_1.png')" alt />
            <img class="decorate d2 ifocus_left" :src="require('../assets/image/stay/d_2.png')" alt />
          </div>
        </div>
        <div class="contentWrap">
          <div class="titleWrap">
            <img :src="require('../assets/image/stay/subtitle.png')" alt />
            <!-- <span>特色民宿</span>
            <div class="gap"></div>-->
          </div>
          <div v-for="(item,index) in dataInfo" :key="index">
            <div v-if="index%2==0" class="group leftStyle ifocus_shadow_gray">
              <div class="summaryWrap">
                <div class="layout_pic maskWrapD">
                  <img :src="baseUrl+item.main_image_id" alt />
                </div>
                <div class="layout_text">
                  <span class="title textline1">{{item.title}}</span>
                  <div class="starWrap">
                    <m-star-bar
                      class="starBar"
                      title="舒适指数："
                      :titleFontSize="15"
                      titleColor="#acacac"
                      :marginRight="0"
                      :num="item.comfortable_star"
                    ></m-star-bar>
                  </div>
                  <div class="starWrap">
                    <m-star-bar
                      class="starBar"
                      title="服务设施："
                      :titleFontSize="15"
                      titleColor="#acacac"
                      :marginRight="0"
                      :num="item.service_star"
                    ></m-star-bar>
                  </div>
                  <div class="detail textline7">{{item.hotel_info}}</div>
                  <div class="more">
                    <div class="order" @click="toDetail">查看详情>></div>
                  </div>
                </div>
                <div class="gap"></div>
              </div>

              <!-- 轮播 -->
              <div class="c_pics g1">
                <div class="swiperWrap swiper1">
                  <swiper
                    id="a1"
                    class="swiper-container"
                    :navigation="{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
                    :autoplay="false"
                    slidesPerView="3"
                    :loop="true"
                  >
                    <swiper-slide
                      v-for="(pic,index) in item.rotationList"
                      :key="index"
                      class="swiper-slide"
                    >
                      <img style="width:308px; height: 203px" :src="baseUrl + pic" alt />
                    </swiper-slide>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                  </swiper>
                </div>
              </div>
            </div>
            <div v-else class="group rightStyle ifocus_shadow_gray">
              <div class="summaryWrap">
                <div class="layout_text">
                  <span class="title textline1">{{item.title}}</span>
                  <div class="starWrap">
                    <m-star-bar
                      class="starBar"
                      title="舒适指数："
                      :titleFontSize="15"
                      titleColor="#acacac"
                      :marginRight="0"
                      :num="item.comfortable_star"
                    ></m-star-bar>
                  </div>
                  <div class="starWrap">
                    <m-star-bar
                      class="starBar"
                      title="服务设施："
                      :titleFontSize="15"
                      titleColor="#acacac"
                      :marginRight="0"
                      :num="item.service_star"
                    ></m-star-bar>
                  </div>
                  <div class="detail textline7">{{item.hotel_info}}</div>
                  <div class="more">
                    <div class="order" @click="toDetail">查看详情>></div>
                  </div>
                </div>
                <div class="layout_pic maskWrapD">
                  <img :src="baseUrl+item.main_image_id" alt />
                </div>

                <div class="gap"></div>
              </div>
              <!-- 轮播 -->
              <div class="c_pics g2">
                <div class="swiperWrap swiper2">
                  <swiper
                    id="a2"
                    class="swiper-container"
                    :navigation="{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
                    :autoplay="false"
                    slidesPerView="3"
                    :loop="true"
                  >
                    <swiper-slide
                      v-for="(pic,index) in item.rotationList"
                      :key="index"
                      class="swiper-slide"
                    >
                      <img style="width:308px; height: 203px" :src="baseUrl + pic" alt />
                    </swiper-slide>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 遮罩 -->
        <div class="iMask" v-show="showFlag">
          <div class="maskWrap">
            <img class="code" :src="require('../assets/image/stay/code2.png')" alt />
            <img
              class="close"
              @click="closeMask"
              :src="require('../assets/image/stay/close.png')"
              alt
            />
          </div>
        </div>
      </div>
    </m-layout-bar>
  </div>
</template>

<script type="text/ecmascript-6">
import LayoutBar from "@/components/LayoutBar";
// import MaskBar from "@/components/maskWrapBar/MaskBar";

import SwiperCore, { Navigation, Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
// import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Autoplay]);
import StarBar from "@/components/starBar";

import axios from "axios";
import config from "@/utils/config";

export default {
  components: {
    [LayoutBar.name]: LayoutBar,
    Swiper,
    SwiperSlide,
    [StarBar.name]: StarBar
    // [MaskBar.name]:MaskBar
  },
  data() {
    return {
      banners: [
        "g1_p1.png",
        "g1_p2.png",
        "g1_p3.png",
        "g2_banner.png",
        "g1_banner.png",
        "g1_p3.png"
      ],
      showFlag: false,
      dataInfo: new Array()
    };
  },
  computed: {
    baseUrl() {
      return config.webBaseUrl;
    }
  },
  mounted() {
    this.getData(config.stayUrl);
  },
  methods: {
    async getData(url) {
      try {
        let res = await axios.get(url);
        if (res.data.code == "0" && res.data.data instanceof Array) {
          if (res.data.data.length > 0) {
            let tempData = JSON.parse(JSON.stringify(res.data.data));
            this.dataInfo = tempData;
            for (let i = 0; i < this.dataInfo.length; i++) {
              this.dataInfo[i].rotationList = new Array();
              let rotationStr = this.dataInfo[i].rotation_chart;
              if (!rotationStr) {
                rotationStr = "";
              }
              let flag = rotationStr.indexOf(",");
              if (flag > 0) {
                let list = rotationStr.split(",");
                this.dataInfo[i].rotationList = list;
              }
            }
            // console.log("res.data.data:", this.dataInfo);
          }
        }
      } catch (e) {
        console.log("res err:", e);
      }
    },
    toDetail() {
      // console.log("去预定！！！！");
      this.showFlag = true;
    },
    closeMask() {
      this.showFlag = false;
    }
  }
};
</script>

<style scoped lang="scss" rel="stylesheet/scss">
@import "src/assets/styles/variables.scss";

.iMask {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  z-index: 99999;
  .maskWrap {
    position: absolute;
    width: 299px;
    height: 345px;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
  }
  .code {
    margin-bottom: 10px;
    cursor: default;
  }
  .close {
    cursor: pointer;
  }
}
.swiper-container {
  --swiper-theme-color: #ff6600;
  --swiper-navigation-color: #90c320; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 40px; /* 设置按钮大小 */
}

.stayWrap {
  margin-bottom: 100px;
  background: $bg-gray3;
  border: 1px solid transparent;
  // 公用
  .gap {
    width: 100%;
    height: 1px;
    margin-top: 24px;
    background: $main-gray;
  }

  .bannerWrap {
    position: relative;
    width: 100%;
    height: 395px;
    > img {
      width: 100%;
    }
    .btnWrap {
      position: absolute;
      width: 233px;
      height: 64px;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 134px;
      z-index: 1;
    }
    .btn {
      width: 233px;
      height: 64px;
      line-height: 64px;
      border: 4px solid $main-white;
      background: $btn-green;
      font-size: 27px;
      font-weight: bold;
      border-radius: 3px;
      text-align: center;
      color: $main-white;
    }
    .decorate {
      position: absolute;
    }
    .decorate.d1 {
      top: -73px;
      left: -36px;
      z-index: -2;
    }
    .decorate.d2 {
      z-index: -2;
      right: -69px;
      top: -73px;
    }
  }
  .contentWrap {
    margin: 79px auto 0 auto;
    width: 1170px;
    .titleWrap {
      position: relative;
      margin: 0 auto 25px auto;
      width: 538px;
      height: 113px;

      > img {
        display: block;
      }
      > span {
        position: absolute;
        display: block;
        width: 178px;
        background: $bg-gray3;
        font-size: 33px;
        color: $main-black;
        text-align: center;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 2;
      }
      > .gap {
        position: absolute;
        bottom: 20px;
        width: 100%;
        height: 4px;
        background: $gap-green;
        z-index: 1;
      }
    }
    .group {
      margin-bottom: 70px;
    }
    .group {
      .summaryWrap {
        padding: 32px 30px 30px 34px;
        border-radius: 5px;
        background: $main-white;
        > .layout_pic {
          display: inline-block;
          position: relative;
          width: 616px;
          height: 421px;
          margin-right: 40px;
          background: $main-gray;
          > img {
            width: 100%;
            height: 100%;
          }
          .code {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
          }
        }
        > .layout_text {
          position: relative;
          display: inline-block;
          width: 450px;
          height: 421px;
          padding-top: 7px;
          // padding-left: 40px;
          text-align: left;
          vertical-align: bottom;
          box-sizing: border-box;
          > .title {
            display: inline-block;
            font-size: 27px;
            color: $main-black;
            margin-bottom: 40px;
          }
          > .starWrap {
            > .title {
              font-size: 15px;
              color: $font-gray;
              vertical-align: text-top;
            }
            > .stars {
              width: 115px;
              display: inline-block;
              > img {
                margin-right: 5px;
              }
            }
          }
          > .detail {
            height: 168px;
            font-size: 13px;
            color: $main-black;
            line-height: 2;
            margin-top: 40px;
            overflow: hidden;
            padding-bottom: 20px;
          }
        }
        .more {
          position: absolute;
          bottom: 0;
          right: 0;
          cursor: pointer;
          .order {
            color: $main-blue;
            &:hover {
              color: $main-blueDark;
            }
          }

          a,
          a:focus,
          a:hover,
          a.active {
            color: $main-blue;
          }
        }
      }

      .c_pics.g2 {
        .swiperWrap {
          .swiper-button-prev,
          .swiper-button-next {
            background: $main-purple;
            &:hover {
              background: $main-purpleDark;
            }
          }
        }
      }
      .c_pics {
        height: 202px;
        background: $main-white;
        padding-bottom: 30px;
        background: $main-white;
        .swiperWrap {
          height: 100%;

          // margin:0 45px;
          // position:relative;
          > .swiper-container {
            padding-left: 66px !important;
            padding-right: 57px !important;
          }
          .swiper-button-prev,
          .swiper-button-next {
            width: 58px;
            height: 205px;
            top: 0;
            margin-top: 0;
            background: $main-blue;
            border-radius: 2px;
            &:hover {
              background: $main-blueDark;
            }
          }

          .swiper-button-prev {
            left: 0;
          }
          .swiper-button-next {
            right: 0;
          }
          .swiper-button-prev:after {
            color: $main-white;
            background-image: url(~@/assets/image/stay/arrowLeft.png) !important;
            background-size: 15px 26px;
            width: 15px;
            height: 26px;
            background-repeat: no-repeat;
            font-family: none;
            content: "";
          }

          .swiper-button-next:after {
            color: $main-white;
            background-image: url(~@/assets/image/stay/arrowRight.png) !important;
            background-size: 15px 26px;
            width: 15px;
            height: 26px;
            background-repeat: no-repeat;
            font-family: none;
            content: "";
          }
          .swiper-container {
            width: 954px;
            padding-left: 10px;
            background: $main-white;
            --swiper-theme-color: #ff6600;
            --swiper-navigation-color: #90c320; /* 单独设置按钮颜色 */
            --swiper-navigation-size: 40px; /* 设置按钮大小 */
          }
        }
      }
    }
    .group.rightStyle {
      .summaryWrap {
        > .layout_text {
          margin-right: 40px;
        }
        > .layout_pic {
          margin-right: 0px;
        }
      }
    }
  }
}
</style>
